import "core-js/modules/es.array.concat.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2cca6ef6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  class: "title"
};
var _hoisted_3 = {
  class: "btn-group"
};
var _hoisted_4 = {
  class: "back-btn"
};
var _hoisted_5 = {
  class: "main-btn"
};
var _hoisted_6 = {
  key: 2,
  class: "discount"
};
var _hoisted_7 = {
  class: "frame"
};
var _hoisted_8 = {
  class: "text"
};
var _hoisted_9 = {
  class: "btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_header_component = _resolveComponent("header-component");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_component), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.isPaidSuccess ? _ctx.t('loading.congratulations_for_your_booking_completed') : _ctx.t('loading.sorry_failed_payment_please_check_the_booking_and_confirm_the_payment_again')), 1), _ctx.isPaidSuccess ? (_openBlock(), _createBlock(_component_svg_icon, {
    key: 0,
    class: "payment-icon",
    iconName: "image_success"
  })) : (_openBlock(), _createBlock(_component_svg_icon, {
    key: 1,
    class: "payment-icon",
    iconName: "image_payfailure"
  })), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_link, {
    class: "link",
    to: "/"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("button", _hoisted_4, _toDisplayString(_ctx.t('loading.home')), 1)];
    }),
    _: 1
  }), _createVNode(_component_router_link, {
    class: "link",
    to: "/".concat(_ctx.locale, "/order?number=").concat(_ctx.bookingNumber, "&email=").concat(_ctx.email)
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("button", _hoisted_5, _toDisplayString(_ctx.t('loading.check_the_booking')), 1)];
    }),
    _: 1
  }, 8, ["to"])]), _ctx.isPaidSuccess ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_svg_icon, {
    class: "discount-icon",
    iconName: "gift"
  }), _createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('order.unlock_exclusive_offers')), 1), _createVNode(_component_router_link, {
    class: "link",
    to: "/".concat(_ctx.locale, "/order?number=").concat(_ctx.bookingNumber, "&email=").concat(_ctx.email, "#event-discount")
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("button", _hoisted_9, _toDisplayString(_ctx.t('order.view_offers')), 1)];
    }),
    _: 1
  }, 8, ["to"])])])) : _createCommentVNode("", true)])], 64);
}