import _slicedToArray from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import { defineComponent, reactive, computed, onUnmounted, watch, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Header from '@/components/Header.vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'Payment',
  components: {
    HeaderComponent: Header,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var t = inject('t');
    var locale = inject('locale');
    var router = useRouter();
    var query = reactive(router.currentRoute.value.query);
    var bookingNumber = computed(function () {
      return query.booking_number;
    });
    var paymentResult = computed(function () {
      return query.result;
    });
    var isPaidSuccess = computed(function () {
      return paymentResult.value === '1';
    });
    var email = computed(function () {
      return window.localStorage.getItem('email');
    });
    var store = useStore();
    var booking = computed(function () {
      return store.state.booking.bookings.booking;
    });
    var bookingRooms = computed(function () {
      var _store$state$booking$;

      return (_store$state$booking$ = store.state.booking.bookings.booking_rooms) === null || _store$state$booking$ === void 0 ? void 0 : _store$state$booking$[0];
    });
    var hotel = computed(function () {
      return store.state.booking.bookings.hotel;
    });

    var addEventPush = function addEventPush() {
      var _booking$value, _bookingRooms$value, _bookingRooms$value2, _booking$value2;

      window.dataLayer.push({
        ecommerce: null
      });
      window.dataLayer.push({
        event: 'purchase',
        email: window.localStorage.getItem('email'),
        ecommerce: {
          purchase: {
            actionField: {
              id: bookingNumber.value,
              affiliation: 'tourbobo',
              revenue: (_booking$value = booking.value) === null || _booking$value === void 0 ? void 0 : _booking$value.price
            },
            products: [{
              name: (_bookingRooms$value = bookingRooms.value) === null || _bookingRooms$value === void 0 ? void 0 : _bookingRooms$value.room_type.name,
              brand: hotel.value.name,
              price: (_bookingRooms$value2 = bookingRooms.value) === null || _bookingRooms$value2 === void 0 ? void 0 : _bookingRooms$value2.room_type.price,
              quantity: (_booking$value2 = booking.value) === null || _booking$value2 === void 0 ? void 0 : _booking$value2.days // Optional

            }]
          }
        }
      });
    };

    if (query.booking_number && email.value) {
      store.dispatch('booking/getBooking', {
        booking_number: query.booking_number,
        email: email.value
      });
    }

    var isSend = false;
    watch([hotel, bookingRooms, booking], function (_ref) {
      var _ref2 = _slicedToArray(_ref, 3),
          newA = _ref2[0],
          newB = _ref2[1],
          newC = _ref2[2];

      if (newA && newB && newC && !isSend && isPaidSuccess.value) {
        addEventPush();
        isSend = true;
      }
    });
    onMounted(function () {
      setTimeout(function () {
        location.href = "/".concat(locale.value, "/order?number=").concat(query.booking_number, "&email=").concat(email.value);
      }, 2500);
    });
    onUnmounted(function () {
      window.localStorage.removeItem('email');
    });

    var checkDiscount = function checkDiscount() {
      return true;
    };

    return {
      t: t,
      isPaidSuccess: isPaidSuccess,
      bookingNumber: bookingNumber,
      email: email,
      locale: locale,
      checkDiscount: checkDiscount
    };
  }
});